ul {
  list-style: none;
  padding: 0;
  margin: 0;
  /* background: #012c6d; */
  text-align: justify;
}

ul li {
  display: block;
  position: relative;
  float: left;
  /* background: #012c6d; */
}

.main-navigation li.active {
  background-color: #e0ab08;
  /* Add any other styles you want for the active menu item */
}

li ul {
  display: none;
}

ul li a {
  display: block;
  padding: 1em;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
}

ul li a:hover,
ul li a:active,
ul li:active,
.mobileview ul li:active {
  background: #e0ab08;
}

li:hover>ul {
  display: block;
  position: absolute;
  z-index: 9;
}

li:hover li {
  float: none;
  border-bottom: 1px solid #fff;
  /* Add a solid line after every sub-menu item */
}

li:hover li:last-child {
  border-bottom: none;
  /* Remove the border for the last sub-menu item */
}

.main-navigation li:hover a {
  background: #012c6d;
}

li:hover li a:hover {
  background: #e0ab08;
}

.main-navigation li ul li {
  border-top: 0;
}

ul ul ul {
  left: 100%;
  top: 0;
}

ul:before,
ul:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

ul:after {
  clear: both;
}

/*Mobile view Styless */

.dropdown-toggle::after {
  content: "";
  display: none;
}

.mobileview ul li:hover>ul {
  display: block;
  position: absolute;
  z-index: 9;
  width: 100%;
}

.mobileview ul li a:active {
  background: #e0ab08;
}



/* latest news and upcoming events custom css */
.event-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: none;
  border-radius: 0.25rem;
  height: 340px !important;
  font-family: "Montserrat", sans-serif !important;
}

.event-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.event-card a {
  color: initial;
  text-decoration: none !important;
}

.event-card a:hover {
  text-decoration: none !important;
}

.event-card .text-muted i {
  margin: 0 10px;
}

.event-card-text p {
  margin: 10px 10px;
}

.event-card h5 {
  margin: 10px 10px;
  font-weight: 600;
  font-size: 17px;
  color: #012c6d;
}

.brown-button {
  margin: 10px 10px;
}

.event-card img {
  width: 100%;
  height: 180px;
}