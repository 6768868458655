.swiper {
    width: 100%;
    height: 500px;
    max-height: 500px;

}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev::after, .swiper-button-next::after {
    font-size: 30px !important;
    color: #012c6d;
    font-weight: bold;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 150px;
    height: 180px;
}

/* .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
} */

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
    .swiper {
        max-height: 600px;
    }

    .mySwiper .swiper-slide {
        display: none;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 576px and up) */
  @media (min-width: 577px) {
    .swiper {
        max-height: 600px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 769px) {
    .swiper {
        max-height: 600px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 993px) {
    .swiper {
        max-height: 600px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1201px) {
    .swiper {
        max-height: 600px;
    }
  }
  
